import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

//guard router
function guardMyRoute(to, from, next)
{
  const publicPages = ['/auth', '/authregister', '/home',''];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  
  //jika membuka halaman yang dilindungi sedangkan blm login maka akan dibawa ke halaman LOGIN
  if(authRequired && !loggedIn)  {
      next('/auth');
  } else {
    next();
  }
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      beforeEnter : guardMyRoute,
      children: [
        {
          name: 'Home',
          beforeEnter : guardMyRoute,
          path: '/home',
          component: () => import('@/views/dashboard/Home'),
        },
        {
          name: 'AuthUser',
          path: '/auth',
          component: () => import('@/views/dashboard/Login'),
        },
        {
          name: 'Dashboard',
          beforeEnter : guardMyRoute,
          path: '/dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Data Induk
        {
          name: 'Customer',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-customer',
          component: () => import('@/views/dashboard/pages/datainduk-customer'),
        },  
        {
          name: 'Grup Barang',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-grupbarang',
          component: () => import('@/views/dashboard/pages/datainduk-grupbarang'),
        },
        {
          name: 'Subgrup Barang',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-subgrupbarang',
          component: () => import('@/views/dashboard/pages/datainduk-subgrupbarang'),
        }, 
        {
          name: 'Merk Barang',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-merk',
          component: () => import('@/views/dashboard/pages/datainduk-merk'),
        },
        {
          name: 'Satuan',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-satuan',
          component: () => import('@/views/dashboard/pages/datainduk-satuan'),
        },
        {
          name: 'Barang',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-barang',
          component: () => import('@/views/dashboard/pages/datainduk-barang'),
        },
        {
          name: 'Price List',
          beforeEnter : guardMyRoute,
          path: 'pages/pricelist',
          component: () => import('@/views/dashboard/pages/pricelist'),
        },
        {
          name: 'Operator',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-operator',
          component: () => import('@/views/dashboard/pages/datainduk-operator'),
        }, 
        {
          name: 'Termin',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-termin',
          component: () => import('@/views/dashboard/pages/datainduk-termin'),
        },
        {
          name: 'Jenis Tindakan',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-jenistindakan',
          component: () => import('@/views/dashboard/pages/datainduk-jenistindakan'),
        },
        {
          name: 'PPN',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-ppn',
          component: () => import('@/views/dashboard/pages/datainduk-ppn'),
        },
        {
          name: 'Area Penjualan',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-areapenjualan',
          component: () => import('@/views/dashboard/pages/datainduk-areapenjualan'),
        },
        {
          name: 'Lot Barang',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-lotbarang',
          component: () => import('@/views/dashboard/pages/datainduk-lotbarang'),
        }, 
        {
          name: 'User',
          beforeEnter : guardMyRoute,
          path: 'pages/datainduk-user',
          component: () => import('@/views/dashboard/pages/datainduk-user'),
        },
        //Invoice
        {
          name: 'Data Invoice',
          beforeEnter : guardMyRoute,
          path: 'pages/invoice-data',
          component: () => import('@/views/dashboard/pages/invoice-data'),
        },
        {
          name: 'Invoice Update',
          beforeEnter : guardMyRoute,
          path: 'pages/invoice-update',
          component: () => import('@/views/dashboard/pages/invoice-update'),
        },
        {
          name: 'Invoice Baru',
          beforeEnter : guardMyRoute,
          path: 'pages/invoice-add',
          component: () => import('@/views/dashboard/pages/invoice-add'),
        },
        // {
        //   name: 'Invoice Layout',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/invoice-add-layout',
        //   component: () => import('@/views/dashboard/pages/invoice-add-layout'),
        // },
        // PAJAK
        {
          name: 'Pajak',
          beforeEnter : guardMyRoute,
          path: 'pages/pajak',
          component: () => import('@/views/dashboard/pages/pajak'),
        },
        // LAPORAN
        // {
        //   name: 'Laporan Per Customer',
        //   beforeEnter : guardMyRoute,
        //   path: 'pages/laporan-invoicepercustomer',
        //   component: () => import('@/views/dashboard/pages/laporan-invoicepercustomer'),
        // },
        {
          name: 'Laporan Per Customer',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-invoicepercustomer',
          component: () => import('@/views/dashboard/pages/laporan-invoicepercustomer'),
        },
        {
          name: 'Laporan Per Operator',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-invoiceperoperator',
          component: () => import('@/views/dashboard/pages/laporan-invoiceperoperator'),
        },
        {
          name: 'Laporan Per Area Jual',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-invoiceperareajual',
          component: () => import('@/views/dashboard/pages/laporan-invoiceperareajual'),
        },
        {
          name: 'Laporan Per Jenis Tindakan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-invoiceperjenistindakan',
          component: () => import('@/views/dashboard/pages/laporan-invoiceperjenistindakan'),
        },
        {
          name: 'Laporan Kwitansi',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-kwitansi',
          component: () => import('@/views/dashboard/pages/laporan-kwitansi'),
        },
        {
          name: 'Laporan Surat Jalan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-suratjalan',
          component: () => import('@/views/dashboard/pages/laporan-suratjalan'),
        },


        {
          name: 'Laporan Invoice',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-invoice',
          component: () => import('@/views/dashboard/pages/laporan-invoice'),
        },
        {
          name: 'Laporan Kwitansi',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-kwitansi',
          component: () => import('@/views/dashboard/pages/laporan-kwitansi'),
        },
        {
          name: 'Laporan Surat Jalan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-suratjalan',
          component: () => import('@/views/dashboard/pages/laporan-suratjalan'),
        },
        {
          name: 'Laporan Unit Penjualan',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-unitpenjualan',
          component: () => import('@/views/dashboard/pages/laporan-unitpenjualan'),
        },
        {
          name: 'Laporan Hpp & Margin',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-hppmargin',
          component: () => import('@/views/dashboard/pages/laporan-hppmargin'),
        },
        {
          name: 'Laporan Sharing Profit',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-sharingprofit',
          component: () => import('@/views/dashboard/pages/laporan-sharingprofit'),
        },
        {
          name: 'Laporan Status Invoice',
          beforeEnter : guardMyRoute,
          path: 'pages/laporan-statusinvoice',
          component: () => import('@/views/dashboard/pages/laporan-statusinvoice'),
        },
        {
          name: 'User Profile',
          path: 'pages/userprofile',
          beforeEnter : guardMyRoute,
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },

        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography-item',
          component: () => import('@/views/dashboard/component/TypographyItem'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        // // Upgrade
        // {
        //   name: 'Upgrade',
        //   path: 'upgrade',
        //   component: () => import('@/views/dashboard/Upgrade'),
        // },
      ],
    },
  ],
})
